import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { LaTeX } from 'latex2react';

const tex = `$$\r\n\\frac{\\delta}{\\delta u} \\int_{birth}^{death} f(life) du = \\mbox{your life}\r\n$$`;

const AboutPage = () => (
  <Layout>
    <SEO title="About Mathapedia" />
    <h2>Mission</h2>
    <div className="well">
      <p>
        Scientific content increasingly relies on the presentation and authoring
        of complex multimedia diagrams and figures, sometimes interactive, to
        convey information in a non-textual way. Wikis and user-generated
        hyper-linked content have both been very successful in the case for
        text---this is what we aim to do for mathematical diagrams.
      </p>
      <p>
        Many professors in higher education who write textbooks know TeX,
        however, they don't often know how to program the Web. The future of
        building interactive user interfaces should lie not in the hands of
        programmers, but in the hands of the expert of a given field---the goal
        of this project is to supply math, physics, and engineering professors
        with a platform to express mathematical concepts to students to provide
        immersive learning environments.
      </p>
      <p>
        Ideally, this projects serves twofold: First, in closing the gap for
        non-web-technical authors to express ideas and concepts through Web
        technology without the knowledge of coding or user interface design, by
        mapping a typesetting language to interactive programming. Second, in
        providing deep, educational experiences for our youth to engage more in
        the sciences, and begin to use exploration and creativity in learning
        through interactive textbooks.
      </p>
      <p>
        The loose structure and nature of user interface design poses a problem
        for documenting science and related interfaces in a consistent manner.
        TeX provides us with some "laws" to obey in order to design the output
        of a text and graphical language around. Hence, we can attempt to create
        a synthesis of a structured user interface specification (TeX) and a
        structured functional specification (HTML5) to provide a publishing
        platform for the current and next generation.
      </p>
      <p>
        The Art is where we can blend these two standards bodies; higher levels
        of abstraction allow people to express their ideas without having to
        worry about the mechanisms by which the technology is rendering their
        works. It is in these environments when people can express themselves
        freely.
      </p>
    </div>
    <h2>Team</h2>
    <div className="well">
      <p>
        The digital textbook platform was created by{" "}
        <a href="https://twitter.com/danpatricklynch" target="twitter">
          Dan Lynch
        </a>
        , working with his advisor, Babak Ayazifar.
      </p>
    </div>
    <div className="rows">
      <ul className="thumbnails">
        <li className="span6">
          <div className="thumbnail">
            <img src="/app/assets/img/bio/lynch.jpg" alt="Dan Lynch" />
            <div className="caption">
              <h3>Dan Lynch</h3>
              <p>
                Dan's interest in mathematics and music inspired him to transfer
                into the college of engineering to learn about Fourier analysis
                at UC Berkeley. Since then, Dan has authored over 300 pages of
                signal processing documentation that is used by the EECS
                department, and has now applied that experience to build the
                mathapedia digital textbook platform with Babak.
              </p>
              <p>
                Remember, you are a function of the sum of living things... 
              </p>
              <LaTeX content={tex}/>
              <p>
                <a
                  data-bypass="true "
                  href="http://www.linkedin.com/in/danielpatricklynch"
                  className="btn btn-primary"
                >
                  LinkedIn
                </a>
              </p>
            </div>
          </div>
        </li>
        <li className="span6">
          <div className="thumbnail">
            <img src="/app/assets/img/bio/ayazifar.jpg" alt="Babak Ayazifar" />
            <div className="caption">
              <h3>Babak Ayazifar</h3>
              <p>
                Dr. Babak Ayazifar received his undergraduate education at the
                California Institute of Technology, and his Master's and
                doctoral training at the Massachusetts Institute of Technology
                (MIT), all in Electrical Engineering (EE). From 1992-1994 he was
                an Associate Member of the Technical Staff in the Communications
                Research Laboratory of the David Sarnoff Research Center in
                Princeton, New Jersey. He participated in the development of
                DirecTV and contributed to the U.S. HDTV Grand Alliance.
                Following his stint with Sarnoff, he returned to MIT to pursue
                his Ph.D. studies in EE. He was appointed Senior Lecturer in the
                spring of 2002, prior to receiving his doctorate, to teach a
                graduate-level course in digital signal processing. From
                2003-2005, Babak was involved in patent prosecution as a
                Technical Specialist in the Intellectual Property &amp;
                Technology Group for the corporate law and litigation firm of
                Ropes &amp; Gray in Boston. Since spring 2005, when he joined
                the Berkeley EECS faculty as a Lecturer, Babak has taught
                Structure and Interpretation of Systems and Signals (EE 20N);
                Signals and Systems (EE 120); and Teaching Techniques for
                Electrical Engineering (EE 301). In all cases, his teaching
                effectiveness ratings have been consistently high, as one might
                expect given his considerable, and award-winning, teaching
                experience at MIT. His teaching honors and awards include the
                Goodwin Medal (1999), MIT's top teaching award for graduate
                students; and promotion to Instructor-G (1996), the highest rank
                in the MIT EECS Department to which a graduate student could be
                promoted and which entailed teaching assignments ordinarily
                reserved for faculty. Babak was also the recipient of the Harold
                L. Hazen Teaching Award (1995), another Departmental award given
                annually to a graduate-student instructor in acknowledgment of
                outstanding teaching ability and performance. Babak is
                co-inventor on one patent, Method and apparatus for providing
                scalable compressed video signal ( U.S. Patent no. 5,387,940).
                He is a member of Tau Beta Pi, the national engineering honor
                society, and Eta Kappa Nu, the electrical engineering honor
                society.
              </p>
              <p>
                <a
                  data-bypass="true"
                  href="http://www.eecs.berkeley.edu/Faculty/Homepages/ayazifar.html"
                  className="btn btn-primary"
                >
                  Homepage
                </a>
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <h2>Special Thanks</h2>
    <div className="well" style={{ textAlign: "center" }}>
      <p>
        Thank you to the great team who built MathJax, the math rendering engine
        of the web:
      </p>
      <p>
        <a href="http://www.mathjax.org/" data-bypass="true" target="mathjax">
          <img
            title="Powered by MathJax"
            src="http://cdn.mathjax.org/mathjax/badge/badge.gif"
            border={0}
            alt="Powered by MathJax"
          />
        </a>
      </p>
    </div>
  </Layout>
)

export default AboutPage
